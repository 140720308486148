import * as d3 from 'd3';

export const logoAnimation = (backgroundColor="#f0f0f0", borderColor="black", innerColor="#468fae") => {

    // Select the SVG container
    const svg = d3.select("#logo-svg");

    // Clear any existing elements in the SVG
    svg.selectAll("*").remove();

    // Define the points of the trapezoid
    const points = [
        [10, 269],   // Top-left
        [110, 195],  // Top-right
        [120, 210],  // Bottom-right
        [10, 290]    // Bottom-left
    ];

    // Append a polygon (trapezoid) to the SVG
    svg.append("polygon")
        .attr("points", points.map(d => d.join(",")).join(" "))
        .attr("fill", borderColor)
        .attr("opacity", 1);

    // Append the inner circle
    svg.append("circle")
        .attr("cx", 178)
        .attr("cy", 128)
        .attr("r", 75)
        .attr("fill", innerColor)         
        .attr("opacity", 1);
    
    // Define the original and hover heights for the bars
    const barWidth = 20;
    const bars = [
        { x: 128, baseY: 200, barHeight: 65, hoverHeight: 90, delay: 0 },  // Bar 1
        { x: 153, baseY: 205, barHeight: 80, hoverHeight: 110, delay: 100 },  // Bar 2
        { x: 178, baseY: 205, barHeight: 60, hoverHeight: 85, delay: 200 }, // Bar 3
        { x: 203, baseY: 200, barHeight: 88, hoverHeight: 115, delay: 300 }  // Bar 4
    ];
    const rectangles = svg.selectAll("rect")
        .data(bars)
        .enter()
        .append("rect")
        .attr("x", d => d.x)
        .attr("y", d => d.baseY - d.barHeight)  
        .attr("width", barWidth)
        .attr("height", d => d.barHeight)
        .attr("fill", backgroundColor);

    // Append the middle circle
    svg.append("circle")
        .attr("cx", 178)
        .attr("cy", 128)
        .attr("r", 80)
        .attr("fill", "none")
        .attr("stroke", backgroundColor)        
        .attr("stroke-width", 17)        
        .attr("opacity", 1);
    
    // Append the outer circle
    svg.append("circle")
        .attr("cx", 178)
        .attr("cy", 128)
        .attr("r", 100)
        .attr("fill", "none")           
        .attr("stroke", borderColor)        
        .attr("stroke-width", 20)       
        .attr("opacity", 1);

    svg.append("polygon")
        .attr("points", "80,177 105,160 115,190 10,269")
        .attr("fill", backgroundColor)
        .attr("opacity", 1);
    
    // Add hover interaction to the entire SVG
    let animationInProgress = false;
    svg.on("mouseover", () => {
        if (!animationInProgress) {  
            animationInProgress = true;  
    
            rectangles
                .transition()
                .delay((d, i) => d.delay)  
                .duration(500)  
                .attr("height", d => d.hoverHeight)
                .attr("y", d => d.baseY - d.hoverHeight)
                .transition()
                .duration(500)  
                .attr("height", d => d.barHeight)
                .attr("y", d => d.baseY - d.barHeight)
                .transition()
                .duration(300)
                .on("end", () => {
                    animationInProgress = false;  
                });
        }
    });
};
