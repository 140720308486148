import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { logoAnimation } from './LogoAnimation';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        logoAnimation("#00407a","white","white");  // Initialize the D3 animation
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    return (
        <nav className="navBar">
            <div className="navContainer">
                <div className="logo">
                    <Link to="/" onClick={closeMenu}>
                        <svg id="logo-svg" className="logo-svg" viewBox="0 0 300 300"></svg>
                        <div className="logo-text">
                            <div className="logo-bar"></div>
                            <div className="logo-description">
                                <p className="line1">SAA 2025</p>
                                <p className="line2">Leuven</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className={`menuContainer ${isOpen ? 'open' : ''}`}>
                    <div className="menu">
                        <ul>
                            <li><Link to="/" onClick={closeMenu}>Home</Link></li>
                            <li><Link to="/Program" onClick={closeMenu}>Program</Link></li>
                            <li><Link to="/Venue" onClick={closeMenu}>Venue</Link></li>
                            <li><Link to="/Leuven" onClick={closeMenu}>Visit Leuven</Link></li>
                            <li><Link to="/Sponsors" onClick={closeMenu}>Sponsors</Link></li>
                            <li><Link to="/Contact" onClick={closeMenu}>Contact</Link></li>
                        </ul>
                    </div>
                    <div className="registerButton">
                        <Link to="/Submit" onClick={closeMenu}>
                            <button>Submit</button>
                        </Link>
                    </div>
                    <div className="registerButton">
                        <Link to="/Register" onClick={closeMenu}>
                            <button>Register</button>
                        </Link>
                    </div>
                </div>
                <div className="burger" onClick={toggleMenu}>
                    <div className={`bar1 ${isOpen ? 'open' : ''}`}></div>
                    <div className={`bar2 ${isOpen ? 'open' : ''}`}></div>
                    <div className={`bar3 ${isOpen ? 'open' : ''}`}></div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
