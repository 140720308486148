import React from 'react';
// import { Link } from 'react-router-dom';
import './ComingSoon.css';

const ComingSoon = () => {
    return (
        <div className="App-ComingSoon">
          <header className="ComingSoon-header">
            <div style={{ fontSize: '50px', fontWeight: 'bold' }}>
              Coming soon
            </div>
            <div className='coming-soon-text'>
              <p>Stay informed by subscribing to our mailing list</p>
              <div className="register-button-container">
                <a className="register-button-link" href="https://mailing.kuleuven.be/f-ff9b5871a55176a6"  target="_blank" rel="noopener noreferrer">Subscribe now</a>
              </div>
            </div>
          </header>
        </div>    
    );
}

export default ComingSoon;