// LEUVEN CITY list
import leuven_cityhall from '../assets/images/leuven_cityhall.jpg';
import leuven_library from '../assets/images/banner_leuven_library.jpg';
import leuven_abdij from '../assets/images/leuven_abdij.jpeg';
import leuven_parcum from '../assets/images/leuven_parcum.jpg';
import leuven_m from '../assets/images/leuven_m.png';
import leuven_beguinage from '../assets/images/leuven_beguinage.png';
import leuven_arenberg from '../assets/images/leuven_arenberg.jpg'
import leuven_oude from '../assets/images/leuven_oude.jpg';

// HOTEL list
import hotelExample from '../assets/images/hotel_example.jpg';
import accomod_penta from '../assets/images/accomod_penta.png';
import accomod_ibis from '../assets/images/accomod_ibis.png';
import accomod_martin from '../assets/images/accomod_martin.png';
import accomod_theater from '../assets/images/accomod_theater.png';
import accomod_carpinus from '../assets/images/accomod_carpinus.png';

// EAT list
import eatExample from '../assets/images/eat_example.jpg'; 
import eat_bite from '../assets/images/eat_bite.jpg'; 
import eat_lifebar from '../assets/images/eat_lifebar.png';
import eat_nosh from '../assets/images/eat_nosh.jpeg';
import eat_hetmoor from '../assets/images/eat_hetmoor.jpg';
import eat_optimist from '../assets/images/eat_optimist.jpg';
import eat_eed from '../assets/images/eat_eed.jpg';
import eat_nirvana from '../assets/images/eat_nirvana.jpg';
import eat_pepenero from '../assets/images/eat_pepenero.jpg';


// DRINK list   
import drinkExample from '../assets/images/drink_example.jpg';
import drink_noircoffeebar from '../assets/images/drink_noircoffeebar.jpeg';
import drink_barboek from '../assets/images/drink_barboek.jpg';
import drink_malz from '../assets/images/drink_malz.jpg';
import drink_decoureur from '../assets/images/drink_decoureur.jpg';


// KEYNOTE list
import keynoteExample from '../assets/images/speaker_example.jpeg';




// TEAM list

// Logistic
import pic_peeters from '../assets/images/team_no_profil2.png';
import pic_dou from '../assets/images/team_dou.jpg';
import pic_verhees from '../assets/images/team_verhees.jpg';
import pic_ji from '../assets/images/team_ji.jpg';
import pic_luijsmans from '../assets/images/team_luijsmans.jpg';
import pic_vanheck from '../assets/images/team_vanheck.jpg';

//  Communication
import teamExample from '../assets/images/speaker_example.jpeg';
import pic_ariens from '../assets/images/team_ariens.jpg';
import pic_kalkan from '../assets/images/team_kalkan.jpg';
import pic_revol from '../assets/images/team_revol.jpg';
import pic_versyp from '../assets/images/team_versyp.jpg';
import pic_frerart from '../assets/images/team_frerart.jpg';
import pic_uyttebroek from '../assets/images/team_uyttebroek.jpg';
import pic_gudrun from '../assets/images/team_gudrun.jpg';
import pic_niemeijer from '../assets/images/team_niemeijer.jpg';
import pic_vermaelen  from '../assets/images/team_vermaelen.jpg';

// Social
import pic_vanraes from '../assets/images/team_venraes.jpg';
import pic_beams from '../assets/images/team_no_profil2.png';
import pic_kunc from '../assets/images/team_kunc.png';

// Seniors
import pic_kuppens from '../assets/images/team_kuppens.jpg';
import pic_myin from '../assets/images/team_myin.jpg';
import pic_kirtley from '../assets/images/team_kirtley.jpg';
import pic_lafit from '../assets/images/team_lafit.jpeg';

// Scientific
import pic_hajduk from '../assets/images/team_hajduk.png';
import pic_ulitzsch from '../assets/images/team_ulitzsch.jpg';
import pic_kaurin from '../assets/images/team_kaurin.jpg';
import pic_lenaert from '../assets/images/team_lenaert.jpg';
import pic_achterhof from '../assets/images/team_achterhof.jpg';
import pic_schreuder from '../assets/images/team_schreuder.png';
import pic_desmet from '../assets/images/team_desmet.png';
import pic_beames from '../assets/images/team_beames.png';



// WORKSHOP list
import workshopExample from '../assets/images/workshop_example.png';



export const img_leuven = {
    leuven_cityhall: leuven_cityhall,
    leuven_library: leuven_library,
    leuven_abdij: leuven_abdij,
    leuven_parcum: leuven_parcum,
    leuven_m: leuven_m,
    leuven_beguinage: leuven_beguinage,
    leuven_arenberg: leuven_arenberg,
    leuven_oude: leuven_oude,
};

export const img_hotel = {
    hotelExample: hotelExample,
    accomod_penta: accomod_penta,
    accomod_ibis: accomod_ibis,
    accomod_martin: accomod_martin,
    accomod_theater: accomod_theater,
    accomod_carpinus: accomod_carpinus,
}

export const img_eat = {
    eatExample: eatExample,
    eat_bite: eat_bite,
    eat_lifebar: eat_lifebar,
    eat_nosh: eat_nosh,
    eat_hetmoor: eat_hetmoor,
    eat_optimist: eat_optimist,
    eat_eed: eat_eed,
    eat_nirvana: eat_nirvana,
    eat_pepenero: eat_pepenero,
}

export const img_drink = {
    drinkExample: drinkExample,
    drink_noircoffeebar: drink_noircoffeebar,
    drink_barboek: drink_barboek,
    drink_malz: drink_malz,
    drink_decoureur: drink_decoureur,
}
  
export const img_keynote = {
    keynoteExample: keynoteExample,
}

export const img_team = {
    teamExample: teamExample,
    pic_ariens: pic_ariens,
    pic_kalkan: pic_kalkan,
    pic_revol: pic_revol,
    pic_versyp: pic_versyp,
    pic_frerart: pic_frerart,
    pic_uyttebroek: pic_uyttebroek,
    pic_peeters: pic_peeters,
    pic_dou: pic_dou,
    pic_verhees: pic_verhees,
    pic_ji: pic_ji,
    pic_luijsmans: pic_luijsmans,
    pic_vanheck: pic_vanheck,
    pic_vanraes: pic_vanraes,
    pic_beams: pic_beams,
    pic_kunc: pic_kunc,
    pic_kuppens: pic_kuppens,
    pic_myin: pic_myin,
    pic_kirtley: pic_kirtley,
    pic_hajduk: pic_hajduk,
    pic_ulitzsch: pic_ulitzsch,
    pic_kaurin: pic_kaurin,
    pic_lenaert: pic_lenaert,
    pic_achterhof: pic_achterhof,
    pic_schreuder: pic_schreuder,
    pic_niemeijer: pic_niemeijer,
    pic_desmet: pic_desmet,
    pic_gudrun: pic_gudrun,
    pic_beames: pic_beames,
    pic_vermaelen: pic_vermaelen,
    pic_lafit: pic_lafit,
}

export const img_workshop = {
    workshopExample: workshopExample,
}


