import './App.css';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar.jsx';
import Footer from './components/Footer.jsx';
import Home from './components/pages/Home.jsx';
import Register from './components/pages/Register.jsx';
import Submit from './components/pages/Submit.jsx';
import Program from './components/pages/Program.jsx';
import Venue from './components/pages/Venue.jsx';
import Leuven from './components/pages/Leuven.jsx';
import Sponsors from './components/pages/Sponsors.jsx';
import Contact from './components/pages/Contact.jsx';
import FAQ from './components/pages/FAQ.jsx';
import ComingSoon from './components/pages/ComingSoon.jsx';
import Keynote from './components/pages/Keynote.jsx'; // Dynamic Keynote component
import Symposium from './components/pages/Symposium.jsx'; // Dynamic Symposium component
import Workshop from './components/pages/Workshop.jsx'; // Dynamic Symposium component

function App() {
  return (
    <div className="App">
      <Navbar />
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Register_dev" element={<Register />} />
          <Route path="/Submit" element={<Submit />} />
          <Route path="/Program_dev" element={<Program />} />
          <Route path="/Venue" element={<Venue />} />
          <Route path="/Leuven" element={<Leuven />} />
          <Route path="/Sponsors_dev" element={<Sponsors />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/FAQ_dev" element={<FAQ />} />
          <Route path="/ComingSoon" element={<ComingSoon />} />
          <Route path="/keynote/:ref" element={<Keynote />} />
          <Route path="/symposium/:ref" element={<Symposium />} />
          <Route path="/workshop/:ref" element={<Workshop />} />
          
          {/* TODO: remove when pages are ready  */}
          <Route path="/Register" element={<ComingSoon />} />
          <Route path="/Program" element={<ComingSoon />} />
          <Route path="/Sponsors" element={<ComingSoon />} />
          <Route path="/FAQ" element={<ComingSoon />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
