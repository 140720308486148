import React from 'react';
// import { Link } from 'react-router-dom';
//import Sponsor1 from "../../assets/images/sponsor1.png";
import './Sponsors.css';
import pdfFile from '../../assets/sponsors_doc.pdf';

const Sponsors = () => {
    return (
        <div className="App">
            <div className="App-Pages">
                <div className="Page-Background"></div>
                <div className="titleCont">
                    <div className="title-top-pages">
                        Sponsors | SAA 2025
                    </div>
                </div> ²
                <div className="ContType4 HomeWideCont">
                    <div className="main-regular regular">
                        <h2 className="regular-title">Sponsorship Opportunities</h2>
                        <p className="regular-description">The SAA 2025 conference offers a unique opportunity for organizations to showcase their products and services to a expert audience of researchers in the field of ambulatory assessment. We offer a range of sponsorship packages to suit your needs and budget. If you are interested in becoming a sponsor, please contact us. </p>
                    </div>
                    <div className="pdf-container">
                        <embed src={pdfFile} type="application/pdf" width="90%" height="800px" />
                    </div>

                </div>

                {/* TODO: when sponsors will be decided */}
                {/* <div className="ContType4 HomeWideCont">
                    <div className= "twocols-container">
                        <div className= "regular">
                            <h2 className="twocols-title-sponsor"><a href="https://m-path.io/landing/">m-Path</a></h2>
                            <p className="regular-description">A platform for intensive longitudinal methods (ESM/EMA), daily life research, mobile sensing and ecological momentary interventions built by researchers for researchers. Because we use m-Path in our own research, we guarantee that our software stays up to date with the latest developments in ESM research! m-Path is suitable for large-scale studies with thousand participants or more. Take a look at the <a href="https://m-path.io/landing/">website</a>.  </p>
                        </div>
                        <div className="twocols-image">
                        <a href="https://m-path.io/landing/"><img src={Sponsor1} alt="sponsor1" /></a>
                        </div>
                    </div>
                </div>
                <div className="ContType4 HomeWideCont">
                    <div className= "twocols-container twocols-container-inverse">
                        <div className="twocols-image">
                            <a href="https://m-path.io/landing/"><img src={Sponsor1} alt="sponsor1" /></a>
                        </div>
                        <div className= "regular">
                            <h2 className="twocols-title-sponsor"><a href="https://m-path.io/landing/">m-Path</a></h2>
                            <p className="regular-description">A platform for intensive longitudinal methods (ESM/EMA), daily life research, mobile sensing and ecological momentary interventions built by researchers for researchers. Because we use m-Path in our own research, we guarantee that our software stays up to date with the latest developments in ESM research! m-Path is suitable for large-scale studies with thousand participants or more. Take a look at the <a href="https://m-path.io/landing/">website</a>.  </p>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default Sponsors;