import React from 'react';

const Submit = () => {
    return (
        <div className="App">
            <div className="App-Pages">
                <div className="Page-Background"></div>
                <div className="titleCont">
                    <div className="title-top-pages">
                        Submit | SAA 2025
                    </div>
                </div>
                <div className="ContType4 HomeWideCont">
                    <div className="main-regular regular">
                        <h2 className="regular-title">Submit abstracts</h2>
                        <p>Clicking on the submit button will take you to our abstract management platform. Please adhere to the following guidelines when submitting an abstract:</p>
                        <p>General</p>
                        <ul>
                            <li>Abstracts are to be written in English and should be submitted electronically using the abstract management platform.</li>
                            <li>There is no limit to the number of abstracts an individual may submit.</li>
                            <li>All authors have consented to be included as co-authors, have read and approved the abstract and its contents, and agree with the results stated. Please note that after your submission further changes cannot be accepted.</li>
                            <li>Abstracts are to be submitted by 31 January 2025 (23:59 CET) at the latest. Abstracts received after this date will not be evaluated.</li>
                            <li>The abstract title should not exceed 20 words. </li>
                            <li>The abstract should not exceed 300 words.</li>
                        </ul>
                        <p>Symposium instructions</p>
                        <ul>
                            <li>The symposium title should not exceed 20 words.</li>
                            <li>The symposium abstract should not exceed 300 words.</li>
                            <li>You should enter 4 sub-papers for the symposium.</li>
                            <li>The sub-papers should not exceed 250 words.</li>
                            <li>The sub-papers should be written in the following structure:
                                <ul>
                                    <li>Background</li>
                                    <li>Methods</li>
                                    <li>Results</li>
                                    <li>Conclusions</li>
                                </ul>
                            </li>
                            <li>One chair is mandatory. A co-chair and/or discussant are optional</li>
                        </ul>
                        <p>Themes</p>
                        <ul>
                            <li>Clinical/Mental health</li>
                            <li>Health behavior</li>
                            <li>Organizational behavior</li>
                            <li>Social relationships (peer group, family, couples, …)</li>
                            <li>Developmental</li>
                            <li>Emotion</li>
                            <li>Methodological innovations</li>
                            <li>Statistical methods</li>
                            <li>Daily life interventions</li>
                        </ul>
                        <div className="registerButton">
                            <a href="https://www.conftool.pro/saa2025/"><button>Submit abstract</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Submit;