import React from 'react';
import './Home.css';
//import Timeline from "../../assets/images/Timeline.png";
import { timelineDates } from '../../constants/timeline_dates';
// import Landing from "../../assets/images/landing-library.png";
import logo_KULeuven_600years from '../../assets/images/logo-600year-white.png';

const Home = () => {
    const lineLength = 400; // The length of the line
    const dotRadius = 5;
    const padding = 20;
//    const totalHeight = lineLength + padding * 2;
    const spacing = lineLength / (timelineDates.length - 1);

    const today = new Date();
    
    return (
        <div className="App">
            <div className="App-Home">
                <div className="landing-picture-container ContType1">
                    <div className="oblique-bar bar1"></div>
                    <div className="oblique-bar bar2"></div>
                    <div className="HomeTitleCont">
                        <div className="landing-text-container">
                            {/* <div className="landing-details"> */}
                                <p className="landing-title">SOCIETY FOR AMBULATORY ASSESSMENT CONFERENCE</p>
                                <p className="landing-location">LEUVEN, BELGIUM</p>
                                <p className="landing-date">MAY 26-28, 2025</p>
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="landing-picture">
                        <div className="oblique-container">
                            <div className="oblique oblique-1"></div>
                            <div className="oblique oblique-2"></div>
                        </div>
                    </div>
                </div>

                <div className="ContType4 HomeWideCont">                    
                    {/* <div className="ContType4"> */}
                    
                        <div className="twocols-container">
                            <div className="regular">
                                <h2 className="regular-title">Call for abstracts and workshops</h2>
                                <p className="regular-description">We are excited to announce that our call for abstracts is now open. Abstracts can be submitted through our submission tool, which can be accessed using via the submit button on the top right.</p>
                                <p className="regular-description">We also have a limited number of workshop slots available. To submit a proposal for a workshop, please send an e-mail to <a href="mailto:info@saa2025.com">info@saa2025.com</a>, including a proposal document (max 1 page). The deadline for submitting a workshop proposal is 1 December 2024.</p>
                                <p className="regular-description">We will also provide <b>10 travel awards</b>, further information on applying for a travel award will follow on the website.</p>

                                <h2 className="regular-title">Conference of the Society of Ambulatory Assessment 2025</h2>
                                <p className="regular-description">With ambulatory assessment methods gaining increasing traction in scientific discourse, the society of ambulatory assessment, founded in 2008, has flourished into a broad, interdisciplinary community of researchers spearheaded by leading research groups around the world. The annual conference of the society provides a meeting ground where researchers discuss exciting implementations and cutting edge methodological developments in the field of ambulatory assessment.</p>
                                {/* Button to register to newsletter */}
                                <div className="register-button-container">
                                    <a className="register-button-link" href="https://mailing.kuleuven.be/f-ff9b5871a55176a6"  target="_blank" rel="noopener noreferrer">Subscribe now</a>
                                </div>
                                <p className="regular-description">This year, the conference will take place in the city of Leuven, Belgium, renowned for its historic university and picturesque university grounds. Proudly hosted by the KU Leuven and organized by its ambulatory-assessment-minded research groups, the conference offers workshops, social/networking activities, and an exciting range of keynote talks, symposia, oral presentations, and poster sessions over a four day period. To stay informed, make sure to subscribe to our mailing list.</p>
                            </div>
                            <div className="twocols-image image-timeline">
                                {/* DATES */}
                                <svg width="400" height={lineLength + padding * 2}>
                                    {timelineDates.map((item, index) => {
                                        const yPosition = padding + index * spacing;
                                        const nextYPosition = index < timelineDates.length - 1 ? yPosition + spacing : null;
                                        const isPast = new Date(item.time) < today;

                                        return (
                                            <g key={index}>
                                                {nextYPosition && (
                                                    <line
                                                        x1="50" y1={yPosition + dotRadius} x2="50" y2={nextYPosition - dotRadius} stroke="#00b3e6" strokeWidth="2" 
                                                        strokeDasharray={isPast ? "0" : "4"} 
                                                    />
                                                )}
                                                <circle 
                                                    cx="50" cy={yPosition} r={dotRadius} 
                                                    fill={isPast ? "#00b3e6" : "#f5f5f5"} 
                                                    stroke="#00b3e6" strokeWidth="2" 
                                                />
                                                <text x="70" y={yPosition - 8} fontSize="15" fontWeight="bold" fill="gray">
                                                    {item.date}
                                                </text>
                                                <text x="70" y={yPosition + 10} fontSize="18" fontWeight="bold" fill="black">
                                                    {item.event}
                                                </text>
                                            </g>
                                        );
                                    })}
                                </svg>
                                {/* <div className="twocols-post">Professor at the University of xxx</div> */}
                            </div>
                        </div>
                    {/* </div> */}
                </div>

                <div className="ContType2 HomeCont">
                    <div className= "regular">
                        <h2 className="regular-title">Ambulatory assessment</h2>
                        <p className="regular-description">Ambulatory Assessment comprises the use of field methods to assess the ongoing behavior, physiology, experience and environmental aspects of people in naturalistic or unconstrained settings. Ambulatory Assessment uses ecologically-valid tools to understand biopsychosocial processes as they unfold naturally in time and in context. </p>
                        <p className="regular-description">Ambulatory Assessment covers a range of real-time data capture methodologies that originate from different scientific disciplines (psychology, medicine, computer science, etc.). These methodologies include but are not limited to:</p>
                        <ul>
                            <li>Experience sampling and ecological momentary assessment</li>
                            <li>Repeated-entry diary techniques</li>
                            <li>Monitoring of physiological function, in combination with or without physical behaviour</li>
                            <li>Acquisition of ambient environmental parameters</li>
                        </ul>
                    </div>
                </div>
                <div className="ContType3 HomeCont">
                    <div className= "twocols-container">
                        <div className= "regular">
                            <h2 className="regular-title">KU Leuven 600</h2>
                            <p><a href="https://www.kuleuven.be/600years/">Join us</a> in celebrating 600 years of science at KU Leuven.</p>
                        </div>
                        <div className="twocols-image">
                            <img src={logo_KULeuven_600years} alt="KU Leuven 600 years" className="kuleuven600-logo" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;