import React, { useState } from 'react';
import './Leuven.css';
import CardSlider from '../CardSlider'; // Import the CardSlider component
import { places, places_eat, places_drink } from '../../constants/places';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faBeer } from '@fortawesome/free-solid-svg-icons';
import logo_KULeuven_600years from '../../assets/images/logo-600year-white.png';




const Leuven = () => {
    const [filterEat, setFilterEat] = useState(null);
    const [filterDrink, setFilterDrink] = useState(null);

    const handleFilterChangeEat = (value) => {
        setFilterEat(value === "all" ? null : parseInt(value, 10));
    };

    const handleFilterChangeDrink = (value) => {
        setFilterDrink(value === "all" ? null : parseInt(value, 10));
    };

    const filteredPlacesEat = filterEat
        ? places_eat.filter((place) => place.eat_cat.includes(filterEat)) // Check if filterEat is in the eat_cat array
        : places_eat;

    const filteredPlacesDrink = filterDrink
        ? places_drink.filter((place) => place.drink_cat === filterDrink)
        : places_drink;

    return (
        <div className="App">
            <div className="App-Pages">
                <div className="Page-Background"></div>
                <div className="titleCont">
                    <div className="title-top-pages">
                        Visit Leuven
                    </div>
                </div>
                <div className="ContType4 HomeCont">
                    <h2 className="regular-title">The city</h2>
                    <div className="gallery-pictures">
                        {places.map((place, index) => (
                            <div key={index} className="picture-section">
                                <a href={place.link} target="_blank" rel="noopener noreferrer">
                                    <img src={place.src} alt={place.alt} className="picture-image" />
                                    <div className="picture-info">
                                        <h3>{place.name}</h3>
                                        <p>{place.location}</p>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                        
                    <div className= "regular leuven-describ">
                        {/* <h2 className="regular-title">What is SAA 2025?</h2> */}
                        <p className="regular-description">Leuven, a vibrant city in the heart of Belgium, is renowned for its rich history, lively atmosphere, and academic prestige. Home to the prestigious KU Leuven, one of Europe's oldest and most esteemed universities, the city boasts a youthful and dynamic energy. Leuven's picturesque streets are lined with stunning Gothic architecture, charming squares, and historic buildings, such as the iconic Town Hall and the beautiful St. Peter's Church. The city seamlessly blends its ancient heritage with a modern, cosmopolitan vibe, making it a unique and captivating destination. </p>
                        <p>To start exploring some of Leuven's iconic locations, clicking on the images above will lead you to further information.</p>
                        {/* <p className="regular-description">In addition to its academic and architectural splendor, Leuven is celebrated for its thriving cultural scene and culinary delights. The city is famous for its beer culture, being the birthplace of the globally renowned Stella Artois brewery. Visitors can enjoy a plethora of cafes, bars, and restaurants, offering everything from traditional Belgian cuisine to international flavors. Leuven's lively events calendar, featuring festivals, concerts, and exhibitions, ensures there is always something exciting happening. Whether exploring its historic sites, enjoying a leisurely stroll through its parks, or indulging in local delicacies, Leuven offers an unforgettable experience for all who visit.</p> */}
                    </div>
                </div>
                <div className="ContType1 HomeCont">
                    <div className= "twocols-container">
                        <div className= "regular">
                            <h2 className="regular-title">KU Leuven 600</h2>
                            <p>The 600th anniversary of the KU Leuven will take place in 2025. To celebrate this scintillating centenary, a selection of events and activities will take place throughout the year. These activities range from walking tours to exhibitions celebrating the heritage of the university. Visitors wishing to participate in the festivities can find further information on the <a href="https://www.kuleuven.be/600years/">KU Leuven 600 website</a>.</p>
                        </div>
                        <div className="twocols-image">
                            <img src={logo_KULeuven_600years} alt="KU Leuven 600 years" className="kuleuven600-logo" />
                        </div>
                    </div>

                </div>
                <div className="ContType2 HomeWideCont">
                    <h2 className="regular-title">Where to Drink</h2>
                    <div className="filter-container">
                        <div className="filter-title">Filter: </div>
                        <div className="filter-button">
                            <button onClick={() => handleFilterChangeDrink('all')}>All</button>
                            <button onClick={() => handleFilterChangeDrink(1)}><FontAwesomeIcon icon={faCoffee} /></button>
                            <button onClick={() => handleFilterChangeDrink(2)}><FontAwesomeIcon icon={faBeer} /></button>
                            {/* <button onClick={() => handleFilterChangeEat(3)}>€€€</button> */}
                        </div>
                    </div>
                    <CardSlider places={filteredPlacesDrink} />
                </div>
                <div className="ContType1 HomeWideCont">
                    <h2 className="regular-title">Where to Eat</h2>
                    <div className="filter-container">
                        <div className="filter-title">Filter: </div>
                        <div className="filter-button">
                            <button onClick={() => handleFilterChangeEat('all')}>All</button>
                            <button onClick={() => handleFilterChangeEat(1)}>Brunch</button>
                            <button onClick={() => handleFilterChangeEat(2)}>Lunch</button>
                            <button onClick={() => handleFilterChangeEat(3)}>Dinner</button>
                        </div>
                    </div>
                    <CardSlider places={filteredPlacesEat} />
                </div>
            </div>
        </div>
    );
};

export default Leuven;
